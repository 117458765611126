import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["chart", "filter", "price", "stock"];
  static values = {
    price: Array,
    stock: Array,
    categories: Array,
    defaultFilter: String,
    translationPriceHigher: String,
    translationPriceCheaper: String,
    translationPriceEqual: String,
    translationStockIn: String,
    translationStockOut: String,
    translationStockUnknown: String,
    translationNoData: String
  }

  connect() {
    this.currentFilter = this.defaultFilterValue || "price";
    this.dataSets = {
      price: this.priceValue,
      stock: this.stockValue
    };
    this.categories = this.categoriesValue;
    this.initChart();
    this.updateButtonsClasses(this.currentFilter);
  }

  initChart() {
    this.updateChart();
  }

  updateChart() {
    const dataCount = this.categories.length;
    let dynamicHeight;
    if (dataCount === 0) {
      dynamicHeight = 300;
    } else if (dataCount === 1) {
      dynamicHeight = 200;
    } else if (dataCount <= 3) {
      dynamicHeight = 250;
    } else if (dataCount <= 4) {
      dynamicHeight = 350;
    } else {
      dynamicHeight = 450;
    }
    const dataValues = this.dataSets[this.currentFilter];
    const rowTotals = dataValues.map(row => row.reduce((a, b) => a + b, 0));

    const series = [
      {
        name: this.currentFilter === "price" ? this.translationPriceHigherValue : this.translationStockInValue,
        data: dataValues.map(row => row[0]),
        color: "#38a169",
      },
      {
        name: this.currentFilter === "price" ? this.translationPriceCheaperValue : this.translationStockOutValue,
        data: dataValues.map(row => row[1]),
        color: "#e53e3e",
      },
      {
        name: this.currentFilter === "price" ? this.translationPriceEqualValue : this.translationStockUnknownValue,
        data: dataValues.map(row => row[2]),
        color: "#3182ce",
      },
    ];

    const options = {
      series: series,
      chart: {
        type: "bar",
        height: dynamicHeight,
        stacked: true,
        toolbar: { show: false },
        animations: { enabled: false },
      },
      noData: {
        text: this.translationNoDataValue,
        align: "center",
        verticalAlign: "middle",
        style: {
          color: '#273243',
          fontSize: '18px',
        }
      },
      grid: {
        show: true,
        xaxis: { lines: { show: false } }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "70%", 
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 5,
              style: { fontSize: '13px', fontWeight: 900 }
            }
          }
        },
      },
      xaxis: {
        categories: this.categories,
        labels: { 
          style: { fontSize: '14px', fontWeight: 400 },
          tooltip: { enabled: true }
        }
      },
      yaxis: {
        labels: { style: { fontSize: '14px', fontWeight: 400 } }
      },
      tooltip: {
        y: {
          formatter: function (val, opts) {
            const dataPointIndex = opts.dataPointIndex;
            const total = rowTotals[dataPointIndex];
            const percentage = ((val / total) * 100).toFixed(2);
            return `${percentage}%`;
          },
        }
      },
      fill: { opacity: 1 },
      colors: series.map((s) => s.color),
      legend: { position: "bottom", horizontalAlign: "center", markers: { shape: 'circle' } },
    };

    if (this.chart) {
      this.chart.updateOptions(options);
    } else {
      let element = this.chartTarget;
      this.chart = new ApexCharts(element, options);
      this.chart.render();
    }
  }

  submitChart(event) {
    event.preventDefault();
    this.currentFilter = event.target.value;
    this.updateChart();
    this.updateButtonsClasses(this.currentFilter);
  }

  updateButtonsClasses(filter) {
    this.priceTarget.classList = "";
    this.stockTarget.classList = "";

    if (filter === "stock") {
      this.stockTarget.classList.add("granularity-btn", "px-2", "py-1", "font-normal", "text-sm", "border", "border-1", "mr-1", "cursor-pointer", "rounded", "hover:bg-gray-100", "bg-blue-50", "text-blue-600", "border-blue-500");
      this.priceTarget.classList.add("granularity-btn", "px-2", "py-1", "font-normal", "text-sm", "border", "border-1", "border-gray-500", "mr-1", "cursor-pointer", "rounded", "hover:bg-gray-100");
    } else {
      this.priceTarget.classList.add("granularity-btn", "px-2", "py-1", "font-normal", "text-sm", "border", "border-1", "mr-1", "cursor-pointer", "rounded", "hover:bg-gray-100", "bg-blue-50", "text-blue-600", "border-blue-500");
      this.stockTarget.classList.add("granularity-btn", "px-2", "py-1", "font-normal", "text-sm", "border", "border-1", "border-gray-500", "mr-1", "cursor-pointer", "rounded", "hover:bg-gray-100");
    }
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
